$.fn.dataTable.ext.errMode = 'none';

$(() => {
	$.fn.dataTable.Api.register('processing()', function (show) {
		return this.iterator('table', function (ctx) {
			ctx.oApi._fnProcessingDisplay(ctx, show);
		});
	});
});

$(document).on('init.dt', function (e, settings) {
	const table = $(settings.nTable);
	const datatable = table.DataTable();
	const wrapper = $(settings.nTableWrapper);
	const refreshContainer = wrapper.find('#dt-refresh');
	const lenContainer = wrapper.find('#dt-len');
	const colsContainer = wrapper.find('#dt-cols');

	if (settings.sDom.includes('#actions')) {
		if (settings.sDom.includes('#dt-refresh') && settings.ajax) {
			$(`
                <button type="button" class="btn btn-sm btn-light" x-data x-tooltip="Refresh" x-tooltip-placement="top">
                    <i class="fas fa-sync"></i>
                </button>
            `)
				.appendTo(refreshContainer)
				.on('click', function () {
					$(settings.nTable).DataTable().ajax.reload();
				})
				.tooltip();
		} else {
			refreshContainer.remove();
		}

		if (
			settings.sDom.includes('#dt-len') &&
			(typeof settings.oInit.bLengthChange === 'undefined' ||
				settings.oInit.bLengthChange === true)
		) {
			const lenButton = $(`
                <button type="button" class="btn btn-sm btn-light dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" x-data x-tooltip="Click to select the number of rows displayed on each page" x-tooltip-placement="top">
                    ${settings._iDisplayLength}
                </button>
            `)
				.tooltip()
				.appendTo(lenContainer)
				.on('focus', function () {
					$(this).trigger('blur');
				});
			const dropdownMenu = $('<div class="dropdown-menu"></div>').appendTo(lenContainer);
			lenButton.tooltip();
			$(`
                <div class="text-center position-relative">
                    <span style="font-size: 16px">
                        Rows
                    </span>
                </div>
            `).appendTo(dropdownMenu);
			$('<div class="dropdown-divider"></div>').appendTo(dropdownMenu);

			for (let i = 0; i < settings.aLengthMenu[1].length; i++) {
				const lenItem = $(
					'<a class="dropdown-item" style="padding-top: 0; padding-bottom: 0"></a>'
				)
					.text(settings.aLengthMenu[1][i])
					.on('click', function () {
						settings = datatable.settings()[0];
						datatable.page.len(settings.aLengthMenu[0][i]).draw();
						lenButton.text(settings.aLengthMenu[1][i]);
						$(this).parent().find('.active').removeClass('active');
						$(this).addClass('active');
					})
					.appendTo(dropdownMenu);

				if (settings.aLengthMenu[0][i] === settings._iDisplayLength) {
					lenItem.addClass('active');
				}
			}
		} else {
			lenContainer.remove();
		}

		if (settings.sDom.includes('#dt-cols')) {
			const colsButton = $(
				`<button type="button" class="btn btn-sm btn-light dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" x-data x-tooltip="Click to display or hide additional columns" x-tooltip-placement="top">
                <i class="fas fa-list"></i>
                </button>`
			)
				.tooltip()
				.appendTo(colsContainer)
				.on('focus', function () {
					$(this).trigger('blur');
				});
			const dropdownMenu = $('<ul class="dropdown-menu"></ul>').appendTo(colsContainer);

			$(`
                <div class="text-center position-relative">
                    <span style="font-size: 16px">
                        Displayed columns
                    </span>
                </div>
            `).appendTo(dropdownMenu);
			$('<div class="dropdown-divider"></div>').appendTo(dropdownMenu);

			datatable.columns().every(function () {
				$('<li></li>')
					.on('click', function (e) {
						e.stopPropagation();
					})
					.append(
						$(
							`<label class="dropdown-item" style="margin-bottom: 0">${
								this.header().innerHTML
							}</label>`
						).prepend(
							$(
								`<input type="checkbox" class="dropdown-item-checkbox mr-2" ${
									this.visible() ? 'checked' : ''
								}>`
							)
								.data('column', this)
								.on('change', function (e) {
									e.stopPropagation();
									const column = $(this).data('column');
									column.visible(!column.visible());
									datatable.columns.adjust();
									$(this).find('input').prop('checked', column.visible());
								})
						)
					)
					.appendTo(dropdownMenu);
			});
		} else {
			colsContainer.remove();
		}
	} else {
		refreshContainer.remove();
		lenContainer.remove();
		colsContainer.remove();
	}

	if (wrapper.find('.dataTables_filter').length) {
		wrapper.find('.row:nth-child(1)').css({
			'margin-top': '0.5rem',
			'margin-bottom': '0.5rem'
		});

		const searchIcon = $('<span/>');
		searchIcon.text('🔍');
		searchIcon.css({
			top: '0',
			bottom: '0',
			left: '0',
			width: '2rem'
		});
		searchIcon.addClass(
			'position-absolute text-center d-flex flex-column justify-content-center'
		);

		$('.dataTables_filter label').addClass('position-relative').prepend(searchIcon);
	} else {
		table.css({
			'border-top': 0
		});
	}
});

$.extend(true, $.fn.dataTable.defaults, {
	// Top
	dom:
		"<'row'<'col-sm-12 col-md-6'f><'#buttons.col-sm-12 col-md-6'>>" +
		// Middle
		"<'row'<'col-sm-12'tr>>" +
		// Bottom
		"<'row'" +
		`
            <'col-12 col-sm-auto flex-grow-1 d-flex align-self-center justify-content-start'
                <'#actions.btn-group'
                    <'#dt-refresh.btn-group dropdown'>
                    <'#dt-len.btn-group dropdown'>
                    <'#dt-cols.btn-group dropdown'>
                >
            >
        ` +
		"<'col-12 col-sm-auto flex-grow-1 d-flex align-self-center justify-content-center'i>" +
		"<'col-12 col-sm-auto flex-grow-1 d-flex align-self-center justify-content-end'p>" +
		'>',
	lengthMenu: [
		[10, 25, 100],
		[10, 25, 100]
	],
	pagingType: 'first_last_numbers',
	language: {
		paginate: {
			previous: '&lt;',
			next: '&gt;',
			first: '«',
			last: '»'
		},
		searchPlaceholder: 'Search...',
		search: '',
		lengthMenu: '_MENU_',
		info: 'Showing _START_-_END_ of _TOTAL_',
		infoEmpty: 'No results found',
		infoFiltered: ''
	},
	responsive: true,
	deferRender: true,
	searchDelay: 200,
	stateSave: true,
	stateDuration: 0,
    stateSaveParams: function(settings, data) {
        // Only save data.columns & data.length *** delete the rest
		  delete data.search;
		  delete data.order;
		  delete data.start;
    }
	// fixedHeader: true // To be added when all graphs are preloaded before data is get from server
});

$(document).on('draw.dt', function (e) {
	const el = $('#' + $(e.target).attr('id') + '_wrapper').find('.pagination');
	el.addClass('pagination-sm');
});

$(document).on('processing.dt', function (e, _, processing) {
	$(e.target).css('opacity', processing ? 0.5 : 1);
});

$(document).on('error.dt', function(e, settings, techNote, message) {
	var table = $(settings.nTable).DataTable();
	$(table.table().body()).html(
	  '<tr><td colspan="100" class="dataTables_empty"><div class="alert alert-danger" role="alert">\
		The action took too long to complete. If the problem persists, please contact support.\
	   </div></td></tr>'
	);
	table.processing(false);
});
